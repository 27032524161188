.illustration-container {
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
}

.fade-appear {
  opacity: 0;
  z-index: 1;
}

.fade-appear.fade-appear-active {
  opacity: 1;
  transition: opacity 200ms linear;
}

.fade-exit {
  opacity: 1;
}

.fade-exit.fade-exit-active {
  opacity: 0;
  transition: opacity 200ms linear;
}

.fade-exit-done {
  opacity: 0;
}

.fade-enter {
  opacity: 0;
  z-index: 1;
}

.fade-enter.fade-enter-active {
  opacity: 1;
  transition: opacity 200ms linear;
}

.Toastify__progress-bar--error {
  background: #FF1D4A !important;
}

.Toastify__toast-icon svg {
  fill: #FF1D4A !important;
}

svg {
  display: block !important;
}